import React from "react";

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

const habitats = (props) => (
    <Row>
        <Col xs={12}>
            <h3 className={`species_general_references_title`}>{props.t('habitats')}</h3>
        </Col>

        {props.habitats.map((habitat, i) => {
            return <Col key={i} md={4} className="mb-2">
                {habitat.img != null ?
                    <span className="habitat_hover">
                        <Image className="habitat_img" src={`http://admin.eei.sibic.org/assets/img/habitats/${habitat.img}`} rounded />
                        <h5 className={`habitat_path_general_title habitat`}>
                            {habitat.habitat}
                        </h5>
                    </span>
                    :
                    <h5 className={`habitat_path_general_title`}>{habitat.habitat}</h5>}
            </Col>
        })}
        <Col xs={12}>
            <hr />
        </Col>
    </Row >
);

export default habitats;