// cuencas.component.js

import React, { Component } from "react";

import axios from "axios";
import { withTranslation } from 'react-i18next';

// Componentes
import SearchInput, { createFilter } from 'react-search-input'
import Specie_info from "../Species/specie_info"
import Footer from "../UI/footer"

// Componentes Bootstrap
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

// CONSTANTES
import { API_URL } from '../../_aux/constants'

// CSS
import "../css/search_input.css"
import "../../App.css"

// Aux functions
const utils = require("../../_aux/aux_functions");
const { capitalize, lowercase, uppercase } = utils;


const KEYS_TO_FILTERS = ['realm']
const KEYS_TO_FILTERS_CLASS = ['class']

class Family extends Component {
    constructor(props) {
        super(props);
        this.searchUpdated_realm = this.searchUpdated_realm.bind(this)
        this.searchUpdated_class = this.searchUpdated_class.bind(this)
        this.changeSpecie = this.changeSpecie.bind(this)

        this.state = {
            isToggleOn_1: false,
            isToggleOn_2: false,
            loading: true,
            api_byName: [],
            api_byRealm: [],
            phylum_res: [],
            order_res: [],
            searchTerm_realm: '',
            searchTerm_class: '',
            show_specie: [],
            language: this.props.i18n.language
        };
    }

    check_metatags(idioma){
        switch (idioma) {
            case "es":
                document.title = "Taxonomía de las Especies Exóticas Invasoras (EEI)"
                document.querySelector('meta[name="description"]').content = "Taxonomía de las Especies Exóticas Invasoras (EEI): Animalia, Chromista, Fungi, Plantae."
                break;
            case "pt":
                document.title = "Taxonomia de Espécies Exóticas Invasoras (EEI)"
                document.querySelector('meta[name="description"]').content = "Taxonomia de Espécies Exóticas Invasoras (EEI): Animalia, Chromista, Fungi, Plantae."
                break;
            default:
                document.title = "Taxonomy of Invasive Alien Species (IAS)"
                document.querySelector('meta[name="description"]').content = "Taxonomy of Invasive Alien Species (IAS): Animalia, Chromista, Fungi, Plantae."
                break;
        }
    }

    crearJsonRealm(arrayDB) {
        console.log("arrayDB", arrayDB)
        var array_final = []
        var array_realm = []
        var realm_total = []
        var ant_realm = ""
        console.log(arrayDB.length)
        arrayDB.forEach((elem, i) => {
            if(!realm_total.includes(elem.realm)){
                var info_realm = { realm: elem.realm, species: [] }
                realm_total.push(elem.realm)
                array_final.push(info_realm)
            }
            array_final.forEach((elemFinal, iFin) => {
                if(elemFinal.realm == elem.realm){
                    array_final[iFin].species.push(elem)
                }
            });
            
            
            /* Antiguo
            if (ant_realm == elem.realm || ant_realm == "") {
                array_realm.push(elem)
                if (i == arrayDB.length - 1) {
                    var info_realm = { realm: elem.realm, species: array_realm }
                    array_final.push(info_realm)

                    // Vacio el array para meter el nuevo valor
                    array_realm = []
                    array_realm.push(elem)
                }
            } else {
                var info_realm = { realm: elem.realm, species: array_realm }
                array_final.push(info_realm)

                // Vacio el array para meter el nuevo valor
                array_realm = []
                array_realm.push(elem)
                if (i == arrayDB.length - 1) {
                    var info_realm = { realm: ant_realm, species: array_realm }
                    array_final.push(info_realm)
                }
            }
            
            ant_realm = elem.realm
            console.log("ant_realm", ant_realm)
            */
            
        });
        console.log("array_final", array_final)
        return array_final
    }

    crearJsonClass(arrayDB) {
        // console.log("arrayDB", arrayDB)
        var array_final = []
        var array_order = []
        var ant_class = ""
        console.log(arrayDB.length)
        arrayDB.forEach((elem, i) => {
            if (ant_class == elem.class || ant_class == "") {
                array_order.push(elem)
                if (i == arrayDB.length - 1) {
                    var info_realm = { class: elem.class, species: array_order }
                    array_final.push(info_realm)

                    // Vacio el array para meter el nuevo valor
                    array_order = []
                    array_order.push(elem)
                }
            } else {
                console.log("elem", elem.class)
                var info_class = { class: ant_class, species: array_order }
                console.log("info_class", info_class)
                array_final.push(info_class)

                // Vacio el array para meter el nuevo valor
                array_order = []
                array_order.push(elem)
                if (i == arrayDB.length - 1) {
                    var info_class = { class: elem.class, species: array_order }
                    console.log("info_class", info_class)
                    array_final.push(info_class)
                }
            }
            ant_class = elem.class
        });
        return array_final
    }

    crearJsonFamily(arrayDB) {
        // console.log("arrayDB", arrayDB)
        var array_final = []
        var array_family = []
        var ant_family = ""
        console.log(arrayDB.length)
        arrayDB.forEach((elem, i) => {
            if (ant_family == elem.family || ant_family == "") {
                array_family.push(elem)
                if (i == arrayDB.length - 1) {
                    var info_family = { family: elem.family, species: array_family }
                    array_final.push(info_family)

                    // Vacio el array para meter el nuevo valor
                    array_family = []
                    array_family.push(elem)
                }
            } else {
                // console.log("elem", elem.family)
                var info_family = { family: ant_family, species: array_family }
                // console.log("info_family", info_family)
                array_final.push(info_family)

                // Vacio el array para meter el nuevo valor
                array_family = []
                array_family.push(elem)
                if (i == arrayDB.length - 1) {
                    var info_family = { family: elem.family, species: array_family }
                    // console.log("info_family", info_family)
                    array_final.push(info_family)
                }
            }
            ant_family = elem.family
        });
        return array_final
    }


    componentDidMount() {
        this.check_metatags(this.props.i18n.language);
        // Llamada con axios a la API de sibic
        var query_2 = API_URL + "/species.php?lang=" + this.props.i18n.language;
        axios.get(query_2).then(async res => {
            this.setState({ api_byName: res.data["species"] });
        })
        // console.log("this.props.i18n.language - ", this.props.i18n.language);
        axios.get(API_URL + "/realm.php?lang=" + this.props.i18n.language).then(async res => {
            const api_byRealm = res.data["species"];
            var array_result = await this.crearJsonRealm(api_byRealm)
            // var array_result_2 = await this.crearJsonRealm_2(api_byRealm)
            // console.log("array_result_2", array_result_2)
            return array_result
        }).then(values => {
            console.log("array_result", values)
            this.setState({ api_byRealm: values, show_specie: values[0].species[0].species_id, loading: false });
        })
    }

    componentWillReceiveProps(next_props) {
        this.check_metatags(next_props.i18n.language);
        this.setState({ language: next_props.i18n.language })
        var query_2 = API_URL + "/species.php?lang=" + next_props.i18n.language;
        axios.get(query_2).then(async res => {
            this.setState({ api_byName: res.data["species"] });
        })


        var query = API_URL + "/realm.php?lang=" + next_props.i18n.language;

        console.log("componentWillReceiveProps - ", this.props.i18n.language);
        console.log("query - ", query);
        axios.get(query).then(async res => {
            const api_byRealm = res.data["species"];
            var array_result = await this.crearJsonRealm(api_byRealm)

            return array_result

        }).then(values => {
            console.log("array_result", values)
            this.setState({ api_byRealm: values, loadinf: false });
        })
    }

    searchUpdated_realm(term) {
        this.setState({ searchTerm_realm: term })
    }
    searchUpdated_class(term) {
        this.setState({ searchTerm_class: term })
    }

    showPhylum(e, phylum) {
        this.setState({ phylum_res: [], isToggleOn_1: false });
        e.preventDefault();
        console.log("e.target.value", phylum)
        axios.get(API_URL + "/realm.php?lang=" + this.state.language + "&phylum=" + phylum).then(async res => {
            const phylum_res = res.data["species"];

            var array_result = await this.crearJsonClass(phylum_res)
            console.log(array_result)
            return array_result;

        }).then(values => {
            this.setState({ phylum_res: values, isToggleOn_1: true });
        })
    }

    showOrder(e, phylum, order) {
        this.setState({ order_res: [], isToggleOn_2: false });
        e.preventDefault();
        console.log(phylum, " - ", order)
        axios.get(API_URL + "/realm.php?lang=" + this.state.language + "&phylum=" + phylum + "&order=" + order).then(async res => {
            const order_res = res.data["species"];

            var array_result = await this.crearJsonFamily(order_res)
            console.log(array_result)
            return array_result;

        })
            .then(values => {
                this.setState({ order_res: values, isToggleOn_2: true });
            })
    }

    closeSubMenu_1(e) {
        e.preventDefault();
        this.setState({ phylum_res: [], isToggleOn_1: false });

    }
    closeSubMenu_2(e) {
        e.preventDefault();
        this.setState({ order_res: [], isToggleOn_2: false });

    }

    changeSpecie(e) {
        e.preventDefault()
        console.log(e.target.id)

        var specie = this.state.api_byName.filter(elem => elem.species_id == e.target.id);
        console.log("changeSpecie handler - ", specie[0].species_id)

        this.setState({ show_specie: specie[0].species_id, isToggleOn_1: false, isToggleOn_2: false });
    }

    render() {
        const { t } = this.props;
        console.log("FAM - ", this.state.order_res)
        // const show_subMenu = "col-3 sub_nav navBar_species" + (this.state.isToggleOn_1 ? " show" : "");
        // const show_subMenu_2 = "col-3 sub_nav_2 navBar_species" + (this.state.isToggleOn_2 ? " show" : "");

        const show_level_1 = "col-4 navBar_species level_1" + (this.state.isToggleOn_1 ? " show" : "");
        const show_level_2 = "col-6 navBar_species level_2" + (this.state.isToggleOn_2 ? " show" : "");


        // Lista para ser filtrada por la constante KEYS_TO_FILTERS
        const filteredRealm = this.state.api_byRealm.filter(createFilter(this.state.searchTerm_realm, KEYS_TO_FILTERS))
        const filteredClass = this.state.phylum_res.filter(createFilter(this.state.searchTerm_class, KEYS_TO_FILTERS_CLASS))


        console.log("IDIOMA Species - ", this.state.language);

        // Listado de especies y familias en la navegación de la izquierda
        var lista_realm = filteredRealm.map((specie, i) => {
            return (
                <Card style={{ borderBottom: "1px solid #fff", borderRight: "0", borderLeft: "0", borderTop: "0" }} key={i} >
                    <Accordion.Toggle style={{ padding: "0.7em" }} className="item_nav_specie item_card_header" as={Card.Header} eventKey={i}>
                        {capitalize(specie.realm)}<i className="fas fa-sort-down" style={{ float: "right" }}></i>
                    </Accordion.Toggle>
                    {specie.species.map((sp, j) => {
                        return (
                            <Accordion.Collapse key={i + j} eventKey={i}>
                                <Card.Body style={{ padding: "0.7em" }} className="item_nav_specie" id={sp.species_id} onClick={e => { this.showPhylum(e, sp.phylum) }} value={sp.phylum}>{capitalize(sp.phylum)}</Card.Body>
                            </Accordion.Collapse>)
                    })}
                </Card>)

        })

        // Listado de especies y familias en la navegación de la izquierda
        var lista_class = filteredClass.map((specie, i) => {
            return (
                <Card style={{ borderBottom: "1px solid #fff", borderRight: "0", borderLeft: "0", borderTop: "0" }} key={i} >
                    <Accordion.Toggle style={{ padding: "0.7em" }} className="item_nav_specie item_card_header" as={Card.Header} eventKey={i}>
                        {capitalize(specie.class)}<i className="fas fa-sort-down" style={{ float: "right" }}></i>
                    </Accordion.Toggle>
                    {specie.species.map((sp, j) => {
                        return (
                            <Accordion.Collapse key={i + j} eventKey={i}>
                                <Card.Body style={{ padding: "0.7em" }} className="item_nav_specie" id={sp.species_id} onClick={e => { this.showOrder(e, sp.phylum, sp.order) }} value={sp.phylum}>{capitalize(sp.order)}</Card.Body>
                            </Accordion.Collapse>)
                    })}
                </Card>)
        })

        var lista_family = this.state.order_res.map((specie, i) => {
            return (
                <Card style={{ borderBottom: "1px solid #fff", borderRight: "0", borderLeft: "0", borderTop: "0" }} key={i} >
                    <Accordion.Toggle style={{ padding: "0.7em" }} className="item_nav_specie item_card_header" as={Card.Header} eventKey={i}>
                        {capitalize(specie.family)}<i className="fas fa-sort-down" style={{ float: "right" }}></i>
                    </Accordion.Toggle>
                    {specie.species.map((sp, j) => {
                        return (
                            <Accordion.Collapse key={i + j} eventKey={i}>
                                <Card.Body style={{ padding: "0.7em" }} className="item_nav_specie" id={sp.species_id} onClick={this.changeSpecie} >{capitalize(sp.taxa)}</Card.Body>
                            </Accordion.Collapse>)
                    })}
                </Card>)

        })

        if (this.state.loading) return <div className="spinner">
            <Spinner animation="grow" variant="success" />
            <Spinner animation="grow" variant="info" />
        </div >

        return (
            <div className="container-fluid container-fluid-custom">
                <div className="row">
                    <div className="col-xs-5 col-sm-5 col-md-4 col-lg-3 border-right-1">

                        <nav className="navbar navBar_species">
                            <ul className="navbar-nav navBar_species_ul list-group">
                                <li className="buscador"><SearchInput placeholder={t('search')} className="search-input" onChange={this.searchUpdated_realm} /></li>
                                <Accordion defaultActiveKey="0">
                                    {lista_realm}
                                </Accordion>
                            </ul>
                        </ nav >
                    </div>

                    {/* SUBMENU CLASES DE LAS ESPECIES */}
                    <div className={show_level_1}>
                        <div className="row">
                            <div className="bg_tranp col-4" onClick={e => { this.closeSubMenu_1(e) }}></div>
                            <div className="bg_info col-8">
                                <button className="btn btn-sm btn-black mt-3" onClick={e => { this.closeSubMenu_1(e) }}>
                                    <i className="fas fa-arrow-left"></i>
                                </button>
                                <nav className="navbar ">
                                    <ul className="navbar-nav navBar_species_ul list-group">
                                        <li className="buscador"><SearchInput placeholder={t('search')} className="search-input" onChange={this.searchUpdated_class} /></li>
                                        <Accordion defaultActiveKey="0">
                                            {lista_class}
                                        </Accordion>
                                    </ul>
                                </ nav >
                            </div>
                        </div>
                    </div>
                    {/* SUBMENU CLASES DE LAS ESPECIES */}
                    <div className={show_level_2}>
                        <div className="row">
                            <div className="bg_tranp_2 col-7" onClick={e => { this.closeSubMenu_2(e) }}></div>
                            <div className="bg_info_2 col-5">
                                <button className="btn btn-sm btn-black mt-3" onClick={e => { this.closeSubMenu_2(e) }}>
                                    <i className="fas fa-arrow-left"></i>
                                </button>
                                <nav className="navbar ">
                                    <ul className="navbar-nav navBar_species_ul list-group">
                                        <Accordion defaultActiveKey="0">
                                            {lista_family}
                                        </Accordion>
                                    </ul>
                                </ nav >
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-7 col-sm-7 col-md-8 col-lg-9">
                        <Specie_info data_specie={this.state.show_specie} />
                    </div>
                </div>
                <Footer />
            </div >
        );
    }
}

// export default Cuencas;
export default withTranslation()(Family);