import React from "react";

import "../css/not_found.css"


const not_found_page = (props) => (

    <div className="site_not_found">
        <div className="sketch">
            <div className="bee-sketch red"></div>
            <div className="bee-sketch blue"></div>
        </div>

        <h1>
            404: <small>Page Not Found</small>
        </h1>
    </div>

);

export default not_found_page;