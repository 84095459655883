import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import { options } from '../../config/options';

import "bootstrap/dist/css/bootstrap.min.css";


export class Lang_navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: options[0],
        };
    }

    componentDidMount() {
        console.log(this.state)
        switch (this.props.i18n.language) {
            case "en":
                this.setState({ lang: options[0] })
                break;
            case "es":
                this.setState({ lang: options[0] })
                break;
            case "pt":
                this.setState({ lang: options[0] })
                break;
            default:
                break;
        }
    }

    changeLang = (lang) => {
        const { i18n } = this.props;
        const { value } = lang;
        this.setState({ lang });
        i18n.changeLanguage(value);
        console.log("changeLang - ", this.props)
    };

    render() {
        const { lang } = this.state;
        const { t } = this.props;
        return (
            <div className="select_lang">
                <Select
                    defaultValue={options[0]}
                    options={options}
                    value={lang}
                    onChange={this.changeLang}
                    className="App-Select"
                />
            </div>
        );
    }
}
export default withTranslation()(Lang_navigation);