import i18n from 'i18next';
import detector from "i18next-browser-languagedetector";

import { initReactI18next } from 'react-i18next';
import en from '../locale/en';
import pt from '../locale/pt';
import es from '../locale/es';
i18n
    .use(detector)
    .use(initReactI18next)
    .init({
        resources: {
            en,
            pt,
            es,
        },
        lng: "en",
        fallbackLng: 'en',
        debug: process.env.NODE_ENV !== 'production',
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ',',
        },
        react: {
            wait: true,
        },
    });
export default i18n;