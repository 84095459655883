// species.component.js

import React, { Component } from "react";

import axios from "axios";
import { withTranslation } from 'react-i18next';

// Componentes
import SearchInput, { createFilter } from 'react-search-input'
import Specie_info from "./specie_info"
import Footer from "../UI/footer"


// Componentes Bootstrap
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

// CONSTANTES
import { API_URL } from '../../_aux/constants'

// CSS
import "../css/search_input.css"
import "../../App.css"

// Aux functions
const utils = require("../../_aux/aux_functions");
const { capitalize, lowercase, uppercase } = utils;


const KEYS_TO_FILTERS = ['taxa']
const KEYS_TO_FILTERS_FAMILY = ['fam']

class Species extends Component {
    constructor(props) {
        super(props);
        this.searchUpdated_species = this.searchUpdated_species.bind(this)
        this.searchUpdated_family = this.searchUpdated_family.bind(this)
        this.changeSpecie = this.changeSpecie.bind(this);
        this.showspecies = this.showspecies.bind(this);
        this.showfamily = this.showfamily.bind(this);

        this.handleToogle = this.handleToogle.bind(this)

        this.lista_familias = React.createRef()



        this.state = {
            loading: true,
            api_byName: [],
            api_byFamily: [],
            show_specie: [],
            searchTerm_specie: '',
            searchTerm_family: '',
            showSpecieNav: true,
            showFamilyNav: false,
            language: this.props.i18n.language
        };
    }

    componentDidMount() {
        // Llamada con axios a la API de sibic
        // console.log("this.props.i18n.language - ", this.props.i18n.language);

        axios.get(API_URL + "/species.php?lang=" + this.props.i18n.language).then(res => {

            const api_byName = res.data["species"];
            // console.log("api_byName", res.data.species[0].species_id)
            var resutl = res.data.species.sort((a, b) => (a.family > b.family) ? 1 : -1);

            var array_family = []
            var ant_fam = ""
            var arr_byFam = []

            resutl.forEach((elem, i) => {
                var actual_fam = elem.family

                if (elem.family != null) { // Si la familia es null no hacemos nada
                    actual_fam = actual_fam.trim()

                    if (actual_fam == ant_fam || ant_fam == "") {
                        arr_byFam.push(elem)
                        if (i == resutl.length - 1) {
                            var fam_data = { fam: ant_fam, speceies_fam: arr_byFam }
                            // array_family[ant_fam] = arr_byFam;
                            arr_byFam = []
                            arr_byFam.push(elem)
                            array_family.push(fam_data)
                        }
                    } else {
                        var fam_data = { fam: ant_fam, speceies_fam: arr_byFam }
                        // array_family[ant_fam] = arr_byFam;
                        arr_byFam = []
                        arr_byFam.push(elem)
                        array_family.push(fam_data)
                    }
                }
                ant_fam = actual_fam

            });


            // console.log("array_family", array_family)
            // BUENO this.setState({ api_byName, show_specie: res.data["species"], api_byFamily: array_family });

            //Pablo
            this.setState({ api_byName, show_specie: res.data.species[0].species_id, api_byFamily: array_family });

        }).then(() => {
            // console.log("STATE", this.state.api_byFamily)
            this.setState({ loading: false });
        });
    }

    componentWillReceiveProps(next_props) {
        this.setState({ language: next_props.i18n.language })
        var query = API_URL + "/species.php?lang=" + next_props.i18n.language;

        console.log("componentWillReceiveProps - ", this.props.i18n);
        console.log("query - ", query);
        axios.get(query).then(res => {
            const api_byName = res.data["species"];
            var resutl = res.data["species"].sort((a, b) => (a.family > b.family) ? 1 : -1);

            var array_family = []
            var ant_fam = ""
            var arr_byFam = []

            resutl.forEach((elem, i) => {
                var actual_fam = elem.family

                if (elem.family != null) { // Si la familia es null no hacemos nada
                    actual_fam = actual_fam.trim()

                    if (actual_fam == ant_fam || ant_fam == "") {
                        arr_byFam.push(elem)
                        if (i == resutl.length - 1) {
                            var fam_data = { fam: ant_fam, speceies_fam: arr_byFam }
                            // array_family[ant_fam] = arr_byFam;
                            arr_byFam = []
                            arr_byFam.push(elem)
                            array_family.push(fam_data)
                        }
                    } else {
                        var fam_data = { fam: ant_fam, speceies_fam: arr_byFam }
                        // array_family[ant_fam] = arr_byFam;
                        arr_byFam = []
                        arr_byFam.push(elem)
                        array_family.push(fam_data)
                    }
                }
                ant_fam = actual_fam

            });

            // console.log("array_family", array_family)
            // BUENO this.setState({ api_byName, show_specie: res.data["species"], api_byFamily: array_family });

            // Pablo
            this.setState({ api_byName, api_byFamily: array_family });

        }).then(() => {
            // console.log("STATE", this.state.api_byFamily)
            this.setState({ loading: false });
        });
    }

    changeSpecie(e) {
        e.preventDefault()
        console.log(e.target.id)

        var specie = this.state.api_byName.filter(elem => elem.species_id == e.target.id);
        console.log("changeSpecie handler - ", specie[0].species_id)

        // BUENO this.setState({ show_specie: specie[0] });

        // Pablo
        this.setState({ show_specie: specie[0].species_id });
    }

    searchUpdated_species(term) {
        this.setState({ searchTerm_specie: term })
    }
    searchUpdated_family(term) {
        this.setState({ searchTerm_family: term })
    }

    // Para cambiar de menu segun se elija especie o familia
    showspecies() {
        if (!this.state.showSpecieNav) {
            this.setState({ showSpecieNav: !this.state.showSpecieNav, showFamilyNav: !this.state.showFamilyNav })
        }
    }
    showfamily() {
        if (!this.state.showFamilyNav) {
            this.setState({ showFamilyNav: !this.state.showFamilyNav, showSpecieNav: !this.state.showSpecieNav })
        }
    }
    // Fin de cambiar el menú

    handleToogle(i) {
        console.log(this.lista_familias)

        var ul_selected = this.lista_familias.current.childNodes[i + 2]
        var clase = ul_selected.className;
        if (clase.includes("activo")) {
            this.lista_familias.current.childNodes[i + 2].className = 'fam navbar-nav navBar_species_ul list-group'
            console.log("ESTA ACTIVO")
        } else {
            this.lista_familias.current.childNodes[i + 2].className = 'fam navbar-nav navBar_species_ul list-group activo'
            console.log("INACTIVO")
        }

        console.log(ul_selected)
        console.log(clase)
    }

    render() {
        const { t } = this.props;
        // console.log("FAM - ", this.state.api_byFamily)

        // Lista para ser filtrada por la constante KEYS_TO_FILTERS
        const filteredSpecies = this.state.api_byName.filter(createFilter(this.state.searchTerm_specie, KEYS_TO_FILTERS))
        const filteredFamily = this.state.api_byFamily.filter(createFilter(this.state.searchTerm_family, KEYS_TO_FILTERS_FAMILY))

        console.log("IDIOMA Species - ", this.state.language);

        // Listado de especies y familias en la navegación de la izquierda
        var lista_especies = filteredSpecies.map((specie, i) => {
            return (<li key={specie.species_id} className={`nav-item item_nav_specie ${this.state.show_specie == specie.species_id ? `active_red` : null}`} id={specie.species_id} onClick={this.changeSpecie}>
                <a id={specie.species_id} onClick={this.changeSpecie} className="nav-link_specie" >{capitalize(specie.taxa)}</a>
            </li>);
        })

        var lista_family = filteredFamily.map((element, i) => {
            return (
                <Card style={{ borderBottom: "1px solid #fff", borderRight: "0", borderLeft: "0", borderTop: "0" }} key={i} >
                    <Accordion.Toggle style={{ padding: "0.7em" }} className="item_nav_specie item_card_header" as={Card.Header} eventKey={i}>
                        {capitalize(element.fam)}<i className="fas fa-sort-down" style={{ float: "right" }}></i>
                    </Accordion.Toggle>
                    {element.speceies_fam.map((sp, j) => {
                        return (
                            <Accordion.Collapse key={i + j} eventKey={i}>
                                <Card.Body style={{ padding: "0.7em" }} className={`item_nav_specie ${this.state.show_specie == sp.species_id ? `active_red` : null}`} id={sp.species_id} onClick={this.changeSpecie}>{capitalize(sp.taxa)}</Card.Body>
                            </Accordion.Collapse>)
                    })}
                </Card>)
        })

        if (this.state.loading) return <div className="spinner">
            <Spinner animation="grow" variant="success" />
            <Spinner animation="grow" variant="info" />
        </div >

        return (
            <div className="container-fluid container-fluid-custom">
                <div className="row">
                    <div className="col-xs-5 col-sm-5 col-md-4 col-lg-3 border-right-1">

                        <nav className="navbar navBar_species">
                            <ul ref={this.lista_familias} className="navbar-nav navBar_species_ul list-group">
                                {this.state.showFamilyNav
                                    ? <li className="buscador"><SearchInput placeholder={t('search')} className="search-input" onChange={this.searchUpdated_family} /></li>
                                    : <li className="buscador"><SearchInput placeholder={t('search')} className="search-input" onChange={this.searchUpdated_species} /></li>}

                                <li className="nav-item item_nav_specie filtro_especies">
                                    <span
                                        id="f_especie"
                                        className={this.state.showSpecieNav ? 'active' : ''}
                                        onClick={this.showspecies}>{t('Species')}</span>
                                    <span
                                        id="f_family"
                                        className={this.state.showFamilyNav ? 'active' : ''}
                                        onClick={this.showfamily}>{t('Families')}</span>
                                </li>

                                {this.state.showFamilyNav ? (
                                    <Accordion defaultActiveKey="0">
                                        {lista_family}
                                    </Accordion>
                                ) : lista_especies}
                            </ul>
                        </ nav >

                    </div>
                    <div className="col-xs-7 col-sm-7 col-md-8 col-lg-9">
                        <Specie_info data_specie={this.state.show_specie} />
                        
                    </div>
                </div>
                <Footer />
            </div >
        );
    }
}

// export default Species;
export default withTranslation()(Species);
