import React, { Component } from 'react'


import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import CSV_Records from '../Species/records_csv'

import {
    Circle,
    CircleMarker,
    Map,
    Marker,
    Polygon,
    Popup,
    Rectangle,
    TileLayer,
    Tooltip,
} from 'react-leaflet';

const center = [40, -3.2]

class Species_Dots_Map extends Component {
    constructor(props) {
        super(props);

        this.state = {
            records: []
        };
    }

    componentDidMount() {
        console.log("MAP Recodrs - ", this.props.records)
        this.setState({ records: this.props.records })
    }

    render() {
        var allCoords = []
        const markers = this.state.records.map((mark, i) => {
            var coord = [mark.latitude, mark.longitude]

            console.log(i, "-", allCoords.filter(a => a[0] == coord[0]).length)

            allCoords.push(coord);
            return <CircleMarker key={i} style={{ fillOpacity: "unset" }} center={coord} color="purple" radius={allCoords.filter(a => a[0] == coord[0]).length + 1}>
                <Tooltip>
                    <ul style={{ paddingLeft: "1.2em" }} className="map_publication_cite">
                        <li>{this.props.t('locality')}: {mark.locality}</li>
                        <li>{this.props.t('realm')}: {mark.taxonomic_group}</li>
                        <li>{this.props.t('first_introduced')}: {mark.first_record}</li>
                        <li>{this.props.t('date_record')}: {mark.record_date}</li>
                        <li>{this.props.t('country')}: {mark.country}</li>
                        <li>{this.props.t('stretch_place')}: {mark.stretch_place}</li>
                        {mark.records_reference.map((publi, i) => {
                            return <li key={i} className="m-0">
                                {publi.publication_cite}
                            </li>
                        })}
                    </ul>
                </Tooltip>
            </CircleMarker>
        })

        // console.log("allCoords", allCoords)

        return (
            <Row>
                <Col xs={12}>
                    <h3 className={`species_general_references_title`}>{this.props.t('map')} ({this.state.records.length} {this.props.t('map_references')})</h3>
                    <Map center={center}
                        zoom={6}
                        maxZoom={15}
                        attributionControl={true}
                        zoomControl={true}
                        doubleClickZoom={true}
                        scrollWheelZoom={false}
                        dragging={true}
                        animate={true}
                        easeLinearity={0.35}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
                        />
                        {markers}
                    </Map>
                    {/* Botón para descargar en csv los records */}
                    {this.state.records.length != 0
                        ? <CSV_Records records={this.state.records} id_specie={this.state.records[0].species_id} />
                        : <button className="btn btn-sm btn-outline-info mt-3 float-right" disabled>
                            Descargar los registros en CSV (0)
                    </button>}
                </Col>
            </Row>
        )
    }
}

export default Species_Dots_Map