import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.min.css";

// Componentes
import Lang_navigation from "./lang_navigation"

import { withTranslation } from 'react-i18next';
import logo_2 from "../../img/logo-simple.png";

class Navbar extends Component {

    state = {
        isToggleOn: false
    };

    getNavLinkClass = (path) => {
        return this.props.location.pathname === path ? 'active nav-item' : 'nav-item';
    }

    showMenu(e) {
        e.preventDefault();
        this.setState(prevState => ({
            isToggleOn: !prevState.isToggleOn
        }));
    }

    render() {
        const { t } = this.props;
        const collapse_menu = "collapse navbar-collapse" + (this.state.isToggleOn ? " show" : "");

        console.log("this.state.isToggleOn - ", this.state.isToggleOn)
        return (
            <nav className="navbar navbar-expand-lg navbar-dark main_navBar">
                <a className="navbar-brand" href="/">
                    <img src={logo_2} width="auto" height="45" className="d-inline-block align-top" alt="" />
                    {/* Sibic */}
                </a>
                <button onClick={e => { this.showMenu(e) }} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={collapse_menu} id="navbarNav">
                    <ul className="navbar-nav mr-auto">
                        {/* <li className={this.getNavLinkClass("/")}>
                            <NavLink className="nav-link" to="/" >{t("menu_home")}</NavLink>
                        </li> */}
                        <li className={this.getNavLinkClass("/species")} onClick={e => { this.showMenu(e) }}>
                            <NavLink className="nav-link" to="/species">{t("menu_esepecies")}</NavLink>
                        </li>
                        <li className={this.getNavLinkClass("/cuencas")} onClick={e => { this.showMenu(e) }}>
                            <NavLink className="nav-link" to="/cuencas">{t("cuencas")}</NavLink>
                        </li>
                        <li className={this.getNavLinkClass("/common_names")} onClick={e => { this.showMenu(e) }}>
                            <NavLink className="nav-link" to="/common_names">{t("common_names")}</NavLink>
                        </li>
                        <li className={this.getNavLinkClass("/realm")} onClick={e => { this.showMenu(e) }}>
                            <NavLink className="nav-link" to="/realm">{t("realm")}</NavLink>
                        </li>
                        <li className={this.getNavLinkClass("/participants")} onClick={e => { this.showMenu(e) }}>
                            <NavLink className="nav-link" to="/participants">{t("participants")}</NavLink>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="http://www.lifeinvasaqua.com/" target="_blank">Life Invasaqua</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://ibermis.org/" target="_blank">{t("nav-ibermis")}</a>
                        </li>
                        </ul>
                    <span className="navbar-text col-xl-2 col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <Lang_navigation />
                    </span>
                </div>
            </nav>
        )
    }
};

Navbar = withRouter(Navbar);
export default withTranslation()(Navbar);