import React, { Component } from "react";

import { withTranslation } from 'react-i18next';

import Disclaimer from "../UI/disclaimer"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ASPEA from "../../img/logos_colaboradores/aspea-logo.gif";
import CSIC from "../../img/logos_colaboradores/CSIC.jpg";
import GN from "../../img/logos_colaboradores/Colaboradores/gobierno-navarra-logo.gif";
import sibic from "../../img/logos_colaboradores/sibic-logo.gif";
import EFE from "../../img/logos_colaboradores/efe-logo.gif";
import evora from "../../img/logos_colaboradores/universidade-de-evora-logo.gif";
import uicn_fr from "../../img/logos_colaboradores/uicin-logo.gif";
import unav from "../../img/logos_colaboradores/universidad-de-navarra-logo.gif";
import MARE from "../../img/logos_colaboradores/mare-logo.gif";
import MNCN from "../../img/logos_colaboradores/MNCN.JPG";
import UMU from "../../img/logos_colaboradores/universidad-murcia-logo.gif";
import USC from "../../img/logos_colaboradores/usc-logo.gif";
import MTERD_ConBandera from "../../img/logos_colaboradores/Colaboradores/ministerio-transformacion-ecologica-logo.gif";
// import INVASAQUA_LOGO from "../../img/logos_colaboradores/INVASAQUA_LOGO.png";
// import NATURA2000 from "../../img/logos_colaboradores/NATURA2000.png";
// import LIFE from "../../img/logos_colaboradores/LIFE.png";

class Participantes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lang: this.props.i18n.language
        };
    }

    check_metatags(idioma){
        switch (idioma) {
            case "es":
                document.title = "Socios y colaboradores  de la base de datos de las Especies Exóticas Invasoras (EEI)"
                document.querySelector('meta[name="description"]').content = ""
                break;
            case "pt":
                document.title = "Parceiros e colaboradores do banco de dados de Espécies Exóticas Invasoras (EEI)"
                document.querySelector('meta[name="description"]').content = "Esta base de dados é desenvolvido no âmbito do projeto LIFE Invasaqua (LIFE17GIE/ES/000515) Aquatic Invasive Alien Species of Freshwater and Estuarine Systems: Awareness and Prevention in the Iberian Peninsula financiado pelo Programa LIFE da Comissão Europeia."
                break;
            default:
                document.title = "Partners and collaborators of the Invasive Alien Species (IAS) database"
                document.querySelector('meta[name="description"]').content = "This database has been carried out within the framework of the Information and Governance European project LIFE17 GIE/ES/000515 entitled ‘Aquatic Invasive Alien Species of Freshwater and Estuarine Systems: Awareness and Prevention in the Iberian Peninsula (INVASAQUA)’."
                break;
        }
    }

    componentWillMount() {
        this.check_metatags(this.props.i18n.language);
    }

    componentWillReceiveProps(next_props) {        
        this.check_metatags(next_props.i18n.language);
        this.setState({ lang: next_props.i18n.language })

    }

    render() {
        const { t } = this.props;
        return(
        <>
        <Row className="col-12 participants" >
            <Row className="col-12">
                <p className="species_p text-center" style={{ width: "100%", paddingTop: "5em", fontSize: "1.3em" }}>{t("participants_text")}</p>
            </Row>
            <Row className="col-12">
                <h1 className="text-center species_scientific_name participants_title" >{t("participants")}</h1>
            </Row>
            <Row className="col-12" style={{display: "inline-flex", alignItems: "center"}}>
                <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
                    <a href="https://aspea.org" target="_blank">
                        <img src={ASPEA} className="d-inline-block participants_img" alt="" />
                    </a>
                </Col>
                
                <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
                    <a href="http://www.sibic.org" target="_blank">
                        <img src={sibic} className="d-inline-block participants_img" alt="" />
                    </a>
                </Col>
                <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
                    <a href="http://www.mare-centre.pt" target="_blank">
                        <img src={MARE} className="d-inline-block participants_img" alt="" />
                    </a>
                </Col>
                <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
                    <a href="https://www.uevora.pt/" target="_blank">
                        <img src={evora} className="d-inline-block participants_img" alt="" />
                    </a>
                </Col>
                <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
                    <a href="https://www.iucn.org/regions/mediterranean" target="_blank">
                        <img src={uicn_fr} className="d-inline-block participants_img" alt="" />
                    </a>
                </Col>
                <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
                    <a href="https://www.unav.edu/" target="_blank">
                        <img src={unav} className="d-inline-block participants_img" alt="" />
                    </a>
                </Col>
                <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
                    <a href="https://www.csic.es/" target="_blank">
                        <img src={CSIC} className="d-inline-block participants_img" alt="" />
                    </a>
                </Col>
                <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
                    <a href="https://www.mncn.csic.es" target="_blank">
                        <img src={MNCN} className="d-inline-block participants_img" alt="" />
                    </a>
                </Col>
                <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
                    <a href="https://www.um.es/" target="_blank">
                        <img src={UMU} className="d-inline-block participants_img" alt="" />
                    </a>
                </Col>
                <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
                    <a href="https://www.usc.gal" target="_blank">
                        <img src={USC} className="d-inline-block participants_img" alt="" />
                    </a>
                </Col>
                <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
                    <a href="https://www.efeverde.com/" target="_blank">
                        <img src={EFE} className="d-inline-block participants_img" alt="" />
                    </a>
                </Col>
                {/* <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
                    <a href="http://www.lifeinvasaqua.com/" target="_blank">
                        <img src={INVASAQUA_LOGO} className="d-inline-block participants_img" alt="" />
                    </a>
                </Col>
                <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
                    <a href="https://www.miteco.gob.es/es/biodiversidad/temas/espacios-protegidos/red-natura-2000/default.aspx" target="_blank">
                        <img src={NATURA2000} className="d-inline-block participants_img" alt="" />
                    </a>
                </Col>
                <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
                    <a href="https://ec.europa.eu/easme/en/life" target="_blank">
                        <img src={LIFE} className="d-inline-block participants_img" alt="" />
                    </a>
                </Col> */}
            </Row>
            <Row className="col-12">
                <h2 className="text-center species_scientific_name participants_title" >{t("collaborators")}</h2>
            </Row>
            <Row className="col-12" style={{display: "inline-flex", alignItems: "center"}}>
                <Col className="mt-3 p-3" md={6} style={{ textAlign: "center" }} >
                    <a href="https://fundacion-biodiversidad.es/" target="_blank">
                        <img src={MTERD_ConBandera} className="d-inline-block" alt="MTERD_ConBandera" style={{width: "100%", maxWidth: "300px"}}/>
                    </a>
                </Col>
                <Col className="mt-3 p-3" md={6} style={{ textAlign: "center" }} >
                    <a href="https://www.navarra.es" target="_blank">
                        <img src={GN} className="d-inline-block" alt="GN" style={{width: "100%", maxWidth: "300px"}}/>
                    </a>
                </Col>
            </Row>
        </Row>
        <Disclaimer t={t} />
        </>

        )}
}
export default withTranslation()(Participantes);

// const participantes = (props) => (

//     <Row className="col-12 participants pb-5" >
//         <Row className="col-12">
//             <p className="species_p text-center" style={{ width: "100%", paddingTop: "5em", fontSize: "1.3em" }}>{t("participants_text")}</p>
//         </Row>
//         <Row className="col-12">
//             <h1 className="text-center species_scientific_name participants_title" >{t("participants")}</h1>
//         </Row>
//         <Row className="col-12" style={{display: "inline-flex", alignItems: "center"}}>
//             <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
//                 <a href="https://aspea.org" target="_blank">
//                     <img src={ASPEA} className="d-inline-block participants_img" alt="" />
//                 </a>
//             </Col>
            
//             <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
//                 <a href="http://www.sibic.org" target="_blank">
//                     <img src={sibic} className="d-inline-block participants_img" alt="" />
//                 </a>
//             </Col>
//             <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
//                 <a href="http://www.mare-centre.pt" target="_blank">
//                     <img src={MARE} className="d-inline-block participants_img" alt="" />
//                 </a>
//             </Col>
//             <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
//                 <a href="https://www.uevora.pt/" target="_blank">
//                     <img src={evora} className="d-inline-block participants_img" alt="" />
//                 </a>
//             </Col>
//             <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
//                 <a href="https://www.iucn.org/regions/mediterranean" target="_blank">
//                     <img src={uicn_fr} className="d-inline-block participants_img" alt="" />
//                 </a>
//             </Col>
//             <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
//                 <a href="https://www.unav.edu/" target="_blank">
//                     <img src={unav} className="d-inline-block participants_img" alt="" />
//                 </a>
//             </Col>
//             <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
//                 <a href="https://www.csic.es/" target="_blank">
//                     <img src={CSIC} className="d-inline-block participants_img" alt="" />
//                 </a>
//             </Col>
//             <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
//                 <a href="https://www.mncn.csic.es" target="_blank">
//                     <img src={MNCN} className="d-inline-block participants_img" alt="" />
//                 </a>
//             </Col>
//             <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
//                 <a href="https://www.um.es/" target="_blank">
//                     <img src={UMU} className="d-inline-block participants_img" alt="" />
//                 </a>
//             </Col>
//             <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
//                 <a href="https://www.usc.gal" target="_blank">
//                     <img src={USC} className="d-inline-block participants_img" alt="" />
//                 </a>
//             </Col>
//             <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
//                 <a href="https://www.efeverde.com/" target="_blank">
//                     <img src={EFE} className="d-inline-block participants_img" alt="" />
//                 </a>
//             </Col>
//             {/* <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
//                 <a href="http://www.lifeinvasaqua.com/" target="_blank">
//                     <img src={INVASAQUA_LOGO} className="d-inline-block participants_img" alt="" />
//                 </a>
//             </Col>
//             <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
//                 <a href="https://www.miteco.gob.es/es/biodiversidad/temas/espacios-protegidos/red-natura-2000/default.aspx" target="_blank">
//                     <img src={NATURA2000} className="d-inline-block participants_img" alt="" />
//                 </a>
//             </Col>
//             <Col className="mt-3 p-3" md={3} style={{ textAlign: "center" }} >
//                 <a href="https://ec.europa.eu/easme/en/life" target="_blank">
//                     <img src={LIFE} className="d-inline-block participants_img" alt="" />
//                 </a>
//             </Col> */}
//         </Row>
//         <Row className="col-12">
//             <h2 className="text-center species_scientific_name participants_title" >{t("collaborators")}</h2>
//         </Row>
//         <Row className="col-12" style={{display: "inline-flex", alignItems: "center"}}>
//             <Col className="mt-3 p-3" md={6} style={{ textAlign: "center" }} >
//                 <a href="https://fundacion-biodiversidad.es/" target="_blank">
//                     <img src={MTERD_ConBandera} className="d-inline-block" alt="MTERD_ConBandera" style={{width: "100%", maxWidth: "300px"}}/>
//                 </a>
//             </Col>
//             <Col className="mt-3 p-3" md={6} style={{ textAlign: "center" }} >
//                 <a href="https://www.navarra.es" target="_blank">
//                     <img src={GN} className="d-inline-block" alt="GN" style={{width: "100%", maxWidth: "300px"}}/>
//                 </a>
//             </Col>
//         </Row>

//     </Row>

// );
// // participantes = withRouter(participantes);
// export default withTranslation()(participantes);
// // export default participantes;