import React, { Component, useState } from "react";
import { NavLink, withRouter } from 'react-router-dom'

import axios from "axios";

import { withTranslation } from 'react-i18next';

import { Map, TileLayer } from "react-leaflet";
import MarkerCluster_map from "./MarkerCluster_comp";
import { point } from "leaflet";

import HeatmapLayer from 'react-leaflet-heatmap-layer';
import { my_addressPoints } from '../Heatmap/realworld.10000.js';

import CSV_Records from '../../Species/records_csv'

const position_center = [40, -3.2]
const mapStyle = { width: "100%", height: "500px", border: "2px solid #c5c5c5" };


class MyClusterMap_2 extends Component {
    constructor(props) {
        super(props);

        this.check_map = this.check_map.bind(this)

        this.state = {
            loading: true,
            language: this.props.i18n.language,
            records: [],
            coords_heat: [],
            markers: [],
            zoom: 5,
            show_heatmap: true,
            show_cluster: false
        };
    }

    componentDidMount() {
        console.log("MyClusterMap_2 DIDMOUNT", this.props.coords_heat)
        this.setState({ markers: this.props.coords, records: this.props.records, coords_heat: this.props.coords_heat })
    }

    componentWillReceiveProps(next_props) {
        console.log("MyClusterMap_2 next_props", next_props)
        console.log("MyClusterMap_2 this.props.i18n.language - ", next_props.i18n.language);
        this.setState({ markers: this.props.coords, records: this.props.records })

    }

    check_map(e) {
        // console.log("eee", this.state.show_heatmap)
        var actual_zoom = e.target._zoom

        if (parseInt(actual_zoom) > 5) {
            this.setState({ show_heatmap: false, show_cluster: true })
            console.log("eee", e.target._zoom)
            console.log("cluster", this.state.show_cluster)
            console.log("heat", this.state.show_heatmap)
            console.log("--------------------------------")

        } else {
            this.setState({ show_heatmap: true, show_cluster: false })
            console.log("eee", e.target._zoom)
            console.log("cluster", this.state.show_cluster)
            console.log("heat", this.state.show_heatmap)
            console.log("--------------------------------")
        }
    }


    render() {
        const { t } = this.props;
        console.log("this.state.zoom", this.state.zoom)
        // this.setState({ show: show_heatmap })
        return (
            <div>
                <h3 className={`species_general_references_title`}>{t('map')} ({this.state.markers.length} {t('map_references')})</h3>
                <Map
                    center={position_center}
                    zoom={this.state.zoom}
                    maxZoom={15}
                    attributionControl={true}
                    zoomControl={true}
                    doubleClickZoom={true}
                    scrollWheelZoom={false}
                    dragging={true}
                    animate={true}
                    easeLinearity={0.35}
                    style={mapStyle}
                    onzoomend={this.check_map}>
                    <TileLayer
                        // url="https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png"
                        url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                        // url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
                        // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />

                    {this.state.markers.length != 0
                        ? this.state.show_heatmap
                            ? <HeatmapLayer
                                // fitBoundsOnLoad
                                // fitBoundsOnUpdate
                                points={this.state.coords_heat}
                                longitudeExtractor={m => m[1]}
                                latitudeExtractor={m => m[0]}
                                intensityExtractor={m => parseFloat(m[2])} />
                            : null
                        : null
                    }
                    {this.state.markers.length != 0
                        ? this.state.show_cluster
                            ? <MarkerCluster_map markers={this.state.markers} />
                            : <MarkerCluster_map markers={[]} />
                        : null
                    }
                    {/* {this.state.show_heatmap
                        ? <HeatmapLayer
                            // fitBoundsOnLoad
                            // fitBoundsOnUpdate
                            points={my_addressPoints}
                            longitudeExtractor={m => m[1]}
                            latitudeExtractor={m => m[0]}
                            intensityExtractor={m => parseFloat(m[2])} />
                        : this.state.markers.length != 0 ? <MarkerCluster_map markers={this.state.markers} /> : null} */}
                </Map>
                {/* Botón para descargar en csv los records */}
                {this.state.records.length != 0
                    ? <CSV_Records records={this.state.records} id_specie={this.state.records[0].species_id} />
                    : <button className="btn btn-sm btn-outline-info mt-3" disabled>
                        Descargar los registros en CSV (0)
                    </button>}
            </div>
        )
    }
}


MyClusterMap_2 = withRouter(MyClusterMap_2);
export default withTranslation()(MyClusterMap_2);