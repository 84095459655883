import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";

import axios from "axios";

import { withTranslation } from "react-i18next";

import default_img from "../../img/img_no_diponible.png";
import eu_flag_100_png from "../../img/eu_flag_100.png";
import es_flag_100_png from "../../img/es_flag_100.png";
import pt_flag_100_png from "../../img/pt_flag_100.png";
import eu_flag_100 from "../../img/eu_flag.gif";
import es_flag_100 from "../../img/es_flag.gif";
import pt_flag_100 from "../../img/pt_flag.gif";
// Componentes
import Species_Dots_Map from "../Map/species_dots_map";
import MyClusterMap from "../Map/MapCluster/my_cluster_map";
import MyClusterMap_2 from "../Map/MapCluster_component/cluster_map_comp";
import Disclaimer from "../UI/disclaimer";

import Heatmap_comp from "../Map/Heatmap/heat";

// import Test_map from '../Map/test_map'
import MapContainer from "../Map/google_map";

// Subcomponents
import Referencias from "./SubComponents/referencias";
import Cuencas from "./SubComponents/basins";
import Habitats from "./SubComponents/habitats";
import Pathways from "./SubComponents/pathways";
import Web_links from "./SubComponents/web_links";
import Carrousel_images from "./SubComponents/carrousel_images";

// Componentes Bootstrap
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import Figure from "react-bootstrap/Figure";
import FigureImage from "react-bootstrap/FigureImage";

// CONSTANTES
import { API_URL } from "../../_aux/constants";

// CSS
import "../css/species.css";

// Aux functions
const utils = require("../../_aux/aux_functions");
const { capitalize, lowercase, uppercase } = utils;

class Species_Info extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      specie_id: "",
      info_specie: [],
      language: this.props.i18n.language,
      coords: [],
      coords_heat: [],
    };
  }

  componentDidMount() {
    console.log("Species_Info DIDMOUNT", this.props.data_specie);
    this.check_specie_info(this.props.data_specie, this.props.i18n.language);
  }

  componentWillReceiveProps(next_props) {
    console.log("Species_Info next_props", next_props);
    console.log("this.props.i18n.language - ", next_props.i18n.language);
    // console.log("this.state.specie_id - ", this.state.specie_id);
    // console.log("next_props.data_specie.species_id - ", next_props.data_specie.species_id);

    // if (next_props.data_specie != this.state.specie_id && next_props.data_specie != undefined) {
    if (next_props.data_specie != undefined) {
      if (next_props.data_specie != this.state.specie_id) {
        this.setState({ loading: true });
      } else {
        this.setState({ loading: false });
      }
      // Llamada con axios a la API de sibic
      this.check_specie_info(next_props.data_specie, next_props.i18n.language);
      this.setState({ specie_id: next_props.data_specie });
    }
  }

  check_specie_info(id, lang) {
    console.log("ID - ", API_URL + "/species.php?lang=" + lang + "&id=" + id);

    axios
      .get(API_URL + "/species.php?lang=" + lang + "&id=" + id)
      .then((res) => {
        const info_specie = res.data;
        this.setState({ info_specie, specie_id: id, language: lang });
        console.log("info_specie component - ", res.data);
      })
      .then(() => {
        //records
        var records = [];
        var heat_records = [];
        if (this.state.info_specie.records.length != 0) {
          records = this.state.info_specie.records.map((record) => {
            // console.log(record)

            var heat_rec = [];
            heat_rec.push(parseFloat(record.latitude));
            heat_rec.push(parseFloat(record.longitude));
            heat_rec.push("100");
            heat_records.push(heat_rec);

            var text_pop_up =
              'General information:<ul style={{ paddingLeft: "1.2em" }} className="map_publication_cite">' +
              "<li>Locality: " +
              record.locality +
              "</li>" +
              // '<li>Taxonomy: ' + record.taxonomic_group + '</li>' +
              // '<li>' + record.first_record + '</li>' +
              "<li>Record date: " +
              record.record_date +
              "</li>" +
              // '<li>Country: ' + record.country + '</li>' +
              "<li>Place: " +
              record.stretch_place +
              "</li>" +
              "</ul> References:";
            var rec = record.records_reference.map((publi, i) => {
              if (publi.publication_hyperlink == null) {
                return (
                  "<li key={" +
                  i +
                  '} className="m-0">' +
                  publi.publication_cite +
                  "</li>"
                );
              } else {
                return (
                  "<li key={" +
                  i +
                  '} className="m-0"><a href="' +
                  publi.publication_hyperlink +
                  '" target="_blank">' +
                  publi.publication_cite +
                  "</a></li>"
                );
              }
            });

            return {
              position: {
                lng: parseFloat(record.longitude),
                lat: parseFloat(record.latitude),
              },
              text: text_pop_up + "<ul>" + rec.join("") + "</ul>",
            };
          });
        }
        this.setState({
          loading: false,
          coords: records,
          coords_heat: heat_records,
        });
      });
  }

  render() {
    const { t } = this.props;

    if (this.state.loading)
      return (
        <div className="spinner">
          <Spinner animation="grow" variant="success" className="PABLO" />
          <Spinner animation="grow" variant="info" className="PABLO" />
        </div>
      );

    // Nombres comunes de la especie
    /**
     * Se mostarán todos los nombres comunes o solo los del idioma que estemos viendo
     * según estemos en /common_names o no
     */
    var nombres_comunes = "";
    this.state.info_specie["common_names"].map((nom_comun, i) => {
      console.log(
        "URL WINDOW",
        window.location.pathname,
        " - ",
        this.state.language
      );
      nombres_comunes += nom_comun.common_name;
      if (i + 1 == this.state.info_specie["common_names"].length) {
        nombres_comunes += " (" + capitalize(nom_comun.language) + ")";
      } else {
        nombres_comunes += " (" + capitalize(nom_comun.language) + "), ";
      }
      // if(window.location.pathname != "/common_names"){
      //     switch (this.state.language) {
      //         case "en":
      //             if(nom_comun.language == "english"){
      //                 nombres_comunes += nom_comun.common_name;
      //                 nombres_comunes += " (" + capitalize(nom_comun.language) + ") "
      //             }
      //             break;

      //         case "es":
      //             if(nom_comun.language == "spanish"){
      //                 nombres_comunes += nom_comun.common_name;
      //                 nombres_comunes += " (" + capitalize(nom_comun.language) + ") "
      //             }
      //             break;

      //         case "pt":
      //             if(nom_comun.language == "portuguese"){
      //                 nombres_comunes += nom_comun.common_name;
      //                 nombres_comunes += " (" + capitalize(nom_comun.language) + ") "
      //             }
      //             break;

      //         default:
      //             nombres_comunes += nom_comun.common_name;
      //             nombres_comunes += " (" + capitalize(nom_comun.language) + ") "
      //             break;
      //     }
      // }else{
      //     nombres_comunes += nom_comun.common_name;
      //     if (i + 1 == this.state.info_specie["common_names"].length) {
      //         nombres_comunes += " (" + capitalize(nom_comun.language) + ")"
      //     } else {
      //         nombres_comunes += " (" + capitalize(nom_comun.language) + "), ";
      //     }
      // }
    });

    // Sinónimos de la especie
    var sinonimos = "";
    this.state.info_specie["synonyms"].map((synon, i) => {
      if (i + 1 == this.state.info_specie["synonyms"].length) {
        sinonimos += synon.synonym;
      } else {
        sinonimos += synon.synonym + ", ";
      }
    });

    // Crear el enlace para la imagen destacada, si no hay ponemos una por defecto
    var featured_img = (
      <Row>
        <Col xs={12} className="species_col_img">
          <Image src={default_img} fluid />
        </Col>
      </Row>
    );

    if (this.state.info_specie.images.length != 0) {
      featured_img = this.state.info_specie.images.map((img, i) => {
        if (img.featured == 1) {
          return (
            <Row key={i}>
              <Col xs={12} className="species_col_img">
                <Figure>
                  <Figure.Image
                    alt={`${img.file_name}`}
                    src={`http://admin.eei.sibic.org/assets/img/species/${img.file_name}`}
                    fluid
                  />
                  <Figure.Caption className="species_copyright mr-2">
                    &copy; {img.author}
                  </Figure.Caption>
                </Figure>
              </Col>
              {/* <Col xs={12} className="species_col_img">
                            <Image src={`http://admin.eei.sibic.org/assets/img/species/${img.file_name}`} fluid />
                        </Col>
                        <Col xs={12} className="species_col_copyright">
                            <small className="species_copyright">&copy; {img.author} </small>
                        </Col> */}
            </Row>
          );
        }
      });
    }

    // DETECTED SPECIE
    var detected_species_info = "";
    switch (this.state.info_specie.species.detected_species) {
      case "na":
        detected_species_info = t("no_information");
        break;
      case "1":
        detected_species_info = t("detected");
        break;
      case "1-es":
        detected_species_info = t("detected_sp");
        break;
      case "1-po":
        detected_species_info = t("detected_pt");
        break;
      case "0":
        detected_species_info = t("not_detected");
        break;
      case "np":
        detected_species_info = t("no_applicable");
        break;
      default:
        detected_species_info = t("no_information");
        break;
    }

    // TRASLOCATED SPECIE
    var translocated_info = "";
    switch (this.state.info_specie.species.translocated) {
      case "na":
        translocated_info = t("no_information");
        break;
      case "1":
        translocated_info = t("detected");
        break;
      case "1-es":
        translocated_info = t("detected_sp");
        break;
      case "1-po":
        translocated_info = t("detected_pt");
        break;
      case "0":
        translocated_info = t("not_detected");
        break;
      case "np":
        translocated_info = t("no_applicable");
        break;
      default:
        translocated_info = t("no_information");
        break;
    }

    // ESTABLISHED POP
    var established_pop = "";
    switch (this.state.info_specie.species.established_pop) {
      case "na":
        established_pop = t("no_information");
        break;
      case "1":
        established_pop = t("established");
        break;
      case "1-es":
        established_pop = t("established_sp");
        break;
      case "1-po":
        established_pop = t("established_pt");
        break;
      case "0":
        established_pop = t("not_established");
        break;
      case "np":
        established_pop = t("no_applicable");
        break;
      default:
        established_pop = t("no_information");
        break;
    }

    return (
      <div className="specie_info_component">
        <Container className="species_info">
          {featured_img}
          <Row>
            <Col xs={12}>
              <p className="species_scientific_name">
                {this.state.info_specie.species.taxa}
                <span className="species_authority">
                  {this.state.info_specie.species.authority.indexOf("(") != -1
                    ? this.state.info_specie.species.authority
                    : "(" + this.state.info_specie.species.authority + ")"}
                </span>
              </p>
              {nombres_comunes != "" ? (
                <p className="other_names">
                  {t("common_names")}: {nombres_comunes}{" "}
                </p>
              ) : null}
              {this.state.info_specie["synonyms"].length != 0 ? (
                <p className="other_names">
                  {t("synonyms")}: {sinonimos}{" "}
                </p>
              ) : null}
              <p className="species_p mt-4">
                {t("Kingdom")}:{" "}
                <strong className="info_ficha">
                  {this.state.info_specie.species.realm}.
                </strong>
                {t("Phylum")}:{" "}
                <strong className="info_ficha">
                  {this.state.info_specie.species.phylum}.
                </strong>
                {t("Class")}:{" "}
                <strong className="info_ficha">
                  {this.state.info_specie.species.class}.
                </strong>
                {t("Order")}:{" "}
                <strong className="info_ficha">
                  {this.state.info_specie.species.order}.
                </strong>
                {t("Family")}:{" "}
                <strong className="info_ficha">
                  {this.state.info_specie.species.family}.
                </strong>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {this.state.info_specie.species.first_introduced_basin != null ? (
                <h4 className="species_morphology_title">
                  {t("first_introduced")} :{" "}
                  {this.state.info_specie.species.first_introduced_basin},{" "}
                  {this.state.info_specie.species.first_record}
                </h4>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h3 className="species_description_title">{t("description")}</h3>
              <p className="species_p">
                {this.state.info_specie.species.description}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h3 className="species_description_title">
                {t("native_origin")}
              </h3>
              <p className="species_p">
                {this.state.info_specie.species.native_origin}
              </p>
            </Col>
          </Row>

          {this.state.info_specie.species_reference.length != 0 ? (
            <Referencias
              referencias={this.state.info_specie.species_reference}
              t={t}
            />
          ) : null}

          {/* <Species_Dots_Map records={this.state.info_specie.records} t={t} /> */}
          {/* <MyClusterMap t={t} coords={this.state.coords} records={this.state.info_specie.records} /> */}

          <MyClusterMap_2
            t={t}
            coords={this.state.coords}
            records={this.state.info_specie.records}
            coords_heat={this.state.coords_heat}
          />
          {/* <Heatmap_comp /> */}

          {/* {this.state.info_specie.introduced_basins.length != 0 ? <Cuencas introduced_basins={this.state.info_specie.introduced_basins} t={t} /> : null} */}
          <Row>
            <Col lg={{ span: 5, offset: 1 }} md={12}>
              {/* <Geo_map_basins basins={this.state.info_specie.introduced_basins} t={t} /> */}
              <Row>
                <Col lg={12} md={12}>
                  <h3 className={`species_general_references_title`}>
                    {t("detected_species")}:{" "}
                    <span className="detected_specie">
                      {detected_species_info}
                    </span>
                  </h3>
                </Col>
                <Col lg={12} md={12}>
                  <h3 className={`species_general_references_title`}>
                    {t("established_pop")}:{" "}
                    <span className="detected_specie">{established_pop}</span>
                  </h3>
                </Col>
                <Col lg={12} md={12}>
                  <h3 className={`species_general_references_title`}>
                    {t("translocated")}:{" "}
                    <span className="detected_specie">{translocated_info}</span>
                  </h3>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12}>
              <Row>
                <Col lg={12} md={12}>
                  <Row className="">
                    <Col xs={9}>
                      <h3 className={`species_general_references_title`}>
                        {this.state.info_specie.species.european_list != 0 ? (
                          <i
                            style={{ color: "green" }}
                            className="fas fa-check"
                          ></i>
                        ) : (
                          <i
                            style={{ color: "red" }}
                            className="fas fa-times"
                          ></i>
                        )}
                        {t("european_list")}
                      </h3>
                    </Col>
                    <Col xs={3} style={{ alignItems: "end", display: "grid" }}>
                      <img className="flags_list" src={eu_flag_100} alt="" />
                    </Col>
                  </Row>
                </Col>
                <Col lg={12} md={12}>
                  <Row className="">
                    <Col xs={9}>
                      <h3 className={`species_general_references_title`}>
                        {this.state.info_specie.species.spanish_list != 0 ? (
                          <i
                            style={{ color: "green" }}
                            className="fas fa-check"
                          ></i>
                        ) : (
                          <i
                            style={{ color: "red" }}
                            className="fas fa-times"
                          ></i>
                        )}
                        {t("spanish_list")}
                      </h3>
                    </Col>
                    <Col xs={3} style={{ alignItems: "end", display: "grid" }}>
                      <img className="flags_list" src={es_flag_100} alt="" />
                    </Col>
                  </Row>
                </Col>
                <Col lg={12} md={12}>
                  <Row className="">
                    <Col xs={9}>
                      <h3 className={`species_general_references_title`}>
                        {this.state.info_specie.species.portuguese_list != 0 ? (
                          <i
                            style={{ color: "green" }}
                            className="fas fa-check"
                          ></i>
                        ) : (
                          <i
                            style={{ color: "red" }}
                            className="fas fa-times"
                          ></i>
                        )}
                        {t("portuguese_list")}
                      </h3>
                    </Col>
                    <Col xs={3} style={{ alignItems: "end", display: "grid" }}>
                      <img className="flags_list" src={pt_flag_100} alt="" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />

          {this.state.info_specie.habitats.length != 0 ? (
            <Habitats habitats={this.state.info_specie.habitats} t={t} />
          ) : null}
          {this.state.info_specie.pathways.length != 0 ? (
            <Pathways pathways={this.state.info_specie.pathways} t={t} />
          ) : null}

          {this.state.info_specie.webpages.length != 0 ? (
            <Web_links webpages={this.state.info_specie.webpages} t={t} />
          ) : null}
          {this.state.info_specie.images.length != 0 &&
          this.state.info_specie.images.length != 1 ? (
            <Carrousel_images images={this.state.info_specie.images} t={t} />
          ) : null}
        </Container>
        <Disclaimer t={t} />
      </div>
    );
  }
}

Species_Info = withRouter(Species_Info);
export default withTranslation()(Species_Info);
