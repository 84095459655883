// Common_names.component.js

import React, { Component } from "react";

import axios from "axios";
import { withTranslation } from 'react-i18next';

// Componentes
import SearchInput, { createFilter } from 'react-search-input'
import Specie_info from "../Species/specie_info"
import Footer from "../UI/footer"

// Componentes Bootstrap
import Spinner from 'react-bootstrap/Spinner'

// CONSTANTES
import { API_URL } from '../../_aux/constants'

// CSS
import "../css/search_input.css"
import "../../App.css"

// Aux functions
const utils = require("../../_aux/aux_functions");
const { capitalize, lowercase, uppercase } = utils;


const KEYS_TO_FILTERS = ['common_name']

class Common_names extends Component {
    constructor(props) {
        super(props);
        this.searchUpdated_common_name = this.searchUpdated_common_name.bind(this)
        this.changeCommon_name = this.changeCommon_name.bind(this);
        this.showspecies = this.showspecies.bind(this);

        this.state = {
            loading: true,
            api_byCommon_name: [],
            api_byFamily: [],
            show_specie: [],
            searchTerm_common_name: '',
            searchTerm_family: '',
            showSpecieNav: true,
            language: this.props.i18n.language
        };
    }

    check_metatags(idioma){
        switch (idioma) {
            case "es":
                document.title = "Nombres comunes de las Especies Exóticas Invasoras (EEI)"
                document.querySelector('meta[name="description"]').content = "Nombres comunes de las Especies Exóticas Invasoras (EEI): Descripción, origen nativo, referencias generales, mapa de registros, hábitats, vías de entrada, referencias web e imágenes."
                break;
            case "pt":
                document.title = "Nomes comuns de Espécies Exóticas Invasoras (EEI)"
                document.querySelector('meta[name="description"]').content = "Nomes comuns de Espécies Exóticas Invasoras (EEI): Descrição, origem nativa, referências gerais, mapa de registros, habitats, rotas de entrada, referências da web e imagens."
                break;
            default:
                document.title = "Common names of Invasive Alien Species (IAS)"
                document.querySelector('meta[name="description"]').content = "Common names of Invasive Alien Species (IAS): Description, native origin, general references, record map, habitats, pathways, web references and images."
                break;
        }
    }

    componentDidMount() {
        this.check_metatags(this.props.i18n.language);
        // Llamada con axios a la API de sibic
        // console.log("this.props.i18n.language - ", this.props.i18n.language);

        // axios.get("https://sibic.anet.com.es/API/common_names.php?lang=" + this.props.i18n.language).then(res => {
        axios.get(API_URL + "/common_names.php").then(res => {


            const api_byCommon_name = res.data["common_names"];
            console.log("api_byCommon_name", res.data["common_names"])

            this.setState({ api_byCommon_name, show_specie: res.data.common_names[0].species_id });

        }).then(() => {
            this.setState({ loading: false });
        });
    }

    componentWillReceiveProps(next_props) {
        this.check_metatags(next_props.i18n.language);
        
    }

    changeCommon_name(e) {
        e.preventDefault()
        console.log(e.target.id)

        var common_name = this.state.api_byCommon_name.filter(elem => elem.species_id == e.target.id);
        if (common_name[0] != undefined) {
            console.log("changeCommon_name handler - ", common_name[0].species_id)

            this.setState({ show_specie: common_name[0].species_id });
        }
    }

    searchUpdated_common_name(term) {
        this.setState({ searchTerm_common_name: term })
    }

    // Para cambiar de menu segun se elija especie o familia
    showspecies() {
        if (!this.state.showSpecieNav) {
            this.setState({ showSpecieNav: !this.state.showSpecieNav })
        }
    }
    // Fin de cambiar el menú

    render() {
        const { t } = this.props;
        console.log("aaaaaaaaaa ", this.state.show_specie)
        // Lista para ser filtrada por la constante KEYS_TO_FILTERS
        const filteredCommon_name = this.state.api_byCommon_name.filter(createFilter(this.state.searchTerm_common_name, KEYS_TO_FILTERS))

        console.log("IDIOMA Species - ", this.state.language);

        // Listado de especies y familias en la navegación de la izquierda

        // var lista_common_names = filteredCommon_name.map((common, i) => {
        //             return (this.state.show_specie == common.species_id
        //                 ? null
        //                 :
        //                 <li key={common.species_id + i} className="nav-item item_nav_specie" id={common.species_id} onClick={this.changeCommon_name}>
        //                     <a id={common.species_id} className="nav-link_specie" >{capitalize(common.common_name)}<span className="ml-2 common_name_languaje">{"(" + capitalize(common.language) + ")"}</span></a>
        //                 </li>);
        //         })

        var lista_common_names = filteredCommon_name.map((common, i) => {
            return (<li key={common.species_id + i} className={`nav-item item_nav_specie ${this.state.show_specie == common.species_id ? `active_red` : null}`} id={common.species_id} onClick={this.changeCommon_name}>
                <a id={common.species_id} className="nav-link_specie" >{capitalize(common.common_name)}
                    <span className="ml-2 common_name_languaje">
                        {"(" + t(capitalize(common.language)) + ")"}
                    </span>
                </a>
            </li>);
        })

        if (this.state.loading) return <div className="spinner">
            <Spinner animation="grow" variant="success" />
            <Spinner animation="grow" variant="info" />
        </div >

        return (
            <div className="container-fluid container-fluid-custom">
                <div className="row">
                    <div className="col-xs-5 col-sm-5 col-md-4 col-lg-3 border-right-1">

                        <nav className="navbar navBar_species">
                            <ul className="navbar-nav navBar_species_ul list-group">
                                <li className="buscador"><SearchInput placeholder={t('search')} className="search-input" onChange={this.searchUpdated_common_name} /></li>
                                {lista_common_names}
                            </ul>
                        </ nav >
                    </div>
                    <div className="col-xs-7 col-sm-7 col-md-8 col-lg-9">
                        <Specie_info data_specie={this.state.show_specie} />
                    </div>
                </div>
                <Footer />
            </div >
        );
    }
}

// export default Common_names;
export default withTranslation()(Common_names);
