import React from "react";

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// import INVASAQUA_LOGO from "../../img/logos_footer/INVASAQUA_LOGO.png";
// import NATURA2000_LOGO from "../../img/logos_footer/LOGO NATURA2000.png";
// import LIFE_LOGO from "../../img/logos_footer/LOGO_LIFE.jpg";

import INVASAQUA_FOOTER from "../../img/logos_colaboradores/Pie/Life-Invasaqua-logo.gif"
import NATURA2000_FOOTER from "../../img/logos_colaboradores/Pie/Natura-2000-logo.gif"
import LIFE_FOOTER from "../../img/logos_colaboradores/Pie/Life-logo.gif"

const footer = (props) => (

    <>
        <div className="footer_home col-xs-5 col-sm-5 col-md-4 col-lg-3 p-0">
            <div className="logo_footer">
                <div className="image_footer p-1">
                    <a href="http://www.lifeinvasaqua.com/" target="_blank">
                        {/* <img src={INVASAQUA_FOOTER} width="auto" height="40" className="d-inline-block mr-2" alt="INVASAQUA_FOOTER"  /> */}
                        <img src={INVASAQUA_FOOTER} className="d-inline-block" alt="INVASAQUA_FOOTER"  />
                    </a>
                </div>
                <div className="image_footer p-1">
                    <a href="https://www.miteco.gob.es/es/biodiversidad/temas/espacios-protegidos/red-natura-2000/default.aspx" target="_blank">
                        {/* <img src={NATURA2000_FOOTER} width="auto" height="40" className="d-inline-block mr-2" alt="NATURA2000_FOOTER"  /> */}
                        <img src={NATURA2000_FOOTER} className="d-inline-block" alt="NATURA2000_FOOTER"  />
                    </a>
                </div>
                <div className="image_footer p-1">
                    <a href="https://ec.europa.eu/easme/en/life" target="_blank">
                        {/* <img src={LIFE_FOOTER} width="auto" height="40" className="d-inline-block" alt="LIFE_FOOTER"  /> */}
                        <img src={LIFE_FOOTER} className="d-inline-block" alt="LIFE_FOOTER"  />
                    </a>
                </div>
            
        </div>
                
        </div>
    </>
);

export default footer;