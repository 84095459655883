// species.component.js

import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import { withTranslation } from 'react-i18next';

import Disclaimer from "./disclaimer";

import INVASAQUA_FOOTER from "../../img/logos_colaboradores/Pie/Life-Invasaqua-logo.gif"
import NATURA2000_FOOTER from "../../img/logos_colaboradores/Pie/Natura-2000-logo.gif"
import LIFE_FOOTER from "../../img/logos_colaboradores/Pie/Life-logo.gif"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lang: this.props.i18n.language
        };
    }

    check_metatags(idioma){
        switch (idioma) {
            case "es":
                document.title = "Especies Exóticas Invasoras acuáticas de la Península Ibérica"
                document.querySelector('meta[name="description"]').content = "El proyecto LIFE INVASAQUA tiene como principal objetivo apoyar la comunicación, la formación y la difusión de información sobre las Especies Exóticas Invasoras (EEI) acuáticas en España y Portugal."
                break;
            case "pt":
                document.title = "Espécies Exóticas aquáticas invasoras da Península Ibérica"
                document.querySelector('meta[name="description"]').content = "O projeto LIFE INVASAQUA, tem como principal objetivo apoiar a comunicação, a formação e a difusão de informação sobre las Espécies Exóticas Invasoras (EEI) aquáticas em Espanha e Portugal."
                break;
            default:
                document.title = "Aquatic Invasive Alien Species of the Iberian Peninsula"
                document.querySelector('meta[name="description"]').content = "LIFE INVASAQUA, seeks to reduce the introduction and spread of invasive alien species (IAS) in the Iberian Peninsula by increasing public and stakeholder awareness through information, communication and training campaigns."
                break;
        }
    }

    componentWillMount() {
        this.check_metatags(this.props.i18n.language);
    }

    componentWillReceiveProps(next_props) {        
        this.check_metatags(next_props.i18n.language);
        this.setState({ lang: next_props.i18n.language })

    }

    render() {
        const { t } = this.props;
        console.log("IDIOMA - ", this.state.lang);
        return (
            <div className="home">
                <Container fluid>
                    <Row>
                        <Col md="10" lg="8" xl="6">
                            <div className="slogan p-5">
                                <h1 className="h2 mb-5">{t('species_home_title')}</h1>
                                <p className="text_home">{t('home_p1')}</p>
                                <p className="text_home">{t('home_p2')}</p>
                                {/* <div className="copy_div mr-3"> */}
                                    <p className="text_home mb-0">{t('home_mail')}<a href="mailto:sibic@sibic.org">sibic@sibic.org</a></p>
                                    <p className="text_home mb-0">{t('home_deploy')}<a href="https://www.anet.es/" target="_blank" >ANET.es</a></p>
                                {/* </div> */}
                                
                            </div>
                        </Col>
                    </Row>
                    <Row className="logos_home">
                        <div className="">
                            <a href="http://www.lifeinvasaqua.com/" target="_blank">
                                {/* <img src={INVASAQUA_FOOTER} width="auto" height="40" className="d-inline-block mt-2 mb-2 mr-2" alt="INVASAQUA_FOOTER"  /> */}
                                <img src={INVASAQUA_FOOTER} width="auto" height="auto" className="d-inline-block mt-2 mb-2 mr-2" alt="INVASAQUA_FOOTER"  />
                            </a>
                            <a href="https://www.miteco.gob.es/es/biodiversidad/temas/espacios-protegidos/red-natura-2000/default.aspx" target="_blank">
                                {/* <img src={NATURA2000_FOOTER} width="auto" height="40" className="d-inline-block mt-2 mb-2 mr-2" alt="NATURA2000_FOOTER"  /> */}
                                <img src={NATURA2000_FOOTER} width="auto" height="auto" className="d-inline-block mt-2 mb-2 mr-2" alt="NATURA2000_FOOTER"  />
                            </a>
                            <a href="https://ec.europa.eu/easme/en/life" target="_blank">
                                {/* <img src={LIFE_FOOTER} width="auto" height="40" className="d-inline-block mt-2 mb-2" alt="LIFE_FOOTER"  /> */}
                                <img src={LIFE_FOOTER} width="auto" height="auto" className="d-inline-block mt-2 mb-2" alt="LIFE_FOOTER"  />
                            </a>
                    </div>
                            
                    </Row>   
                </Container>
                
                <Disclaimer t={t} site={"home"}/>
                

            </div>
        );
    }
}

// export default Species;
export default withTranslation()(Home);
