import React, { Component } from 'react'
import { CSVLink } from "react-csv";


class CSV_Records extends Component {
    constructor(props) {
        super(props);

        this.state = {
            records: [],
            currentdate: new Date().getTime()
        }
    }

    componentDidMount() {
        console.log("CSV_Records - ", this.props)
        var refer_records = []
        this.props.records.map(record => {
            if (record.records_reference.length != 0) {
                record.records_reference.map(refer => {
                    refer_records.push(Object.assign(refer, record));
                })
            } else {
                refer_records.push(record)
            }
        })
        // console.log("refer_records - ", refer_records)
        this.setState({ records: refer_records, id_specie: this.props.id_specie })

    }

    render() {
        // console.log("CSV_Records STATE - ", this.state.records)

        const headers_csv = [
            // { label: "Specie ID", key: "species_id" },              //Col 1
            { label: "Taxonomic group", key: "taxonomic_group" },   //Col 2
            // { label: "Country", key: "country" },                   //Col 3
            { label: "Stretch place", key: "stretch_place" },       //Col 4
            { label: "Locality", key: "locality" },                 //Col 5
            { label: "Record date", key: "record_date" },           //Col 6
            { label: "Reference", key: "reference" },               //Col 7
            // { label: "Title", key: "title" },                       //Col 8
            // { label: "Author", key: "author" },                     //Col 9
            // { label: "Year", key: "year" },                         //Col 10
            // { label: "Publication cite", key: "publication_cite" }, //Col 11
            // { label: "Publication type", key: "publication_type" }, //Col 12
            // { label: "Journal", key: "journal" },                   //Col 13
            { label: "Latitude", key: "latitude" },                 //Col 14
            { label: "Longitude", key: "longitude" }                //Col 15
        ];

        var array_csv = this.state.records.map(record => {
            return {
                // species_id: record.species_id,              //Col 1
                taxonomic_group: record.taxonomic_group,    //Col 2
                // country: record.country,                    //Col 3
                stretch_place: record.stretch_place,        //Col 4
                locality: record.locality,                  //Col 5
                record_date: record.record_date,            //Col 6
                reference: record.reference,                    //Col 7
                // title: record.title,                        //Col 8
                // author: record.author,                      //Col 9
                // year: record.year,                          //Col 10
                // publication_cite: record.publication_cite,  //Col 11
                // publication_type: record.publication_type,  //Col 12
                // journal: record.journal,                    //Col 13
                latitude: record.latitude,                  //Col 14
                longitude: record.longitude                 //Col 15
            };
        })

        return (
            <CSVLink data={array_csv} filename={`specie_${this.state.id_specie}_${this.state.currentdate}.csv`} headers={headers_csv} separator={";"} className="btn btn-sm btn-info mt-3">
                Descargar los registros en CSV ({array_csv.length})
            </CSVLink>
        );
    }
}

export default CSV_Records