import React, { Component } from 'react';
import { render } from 'react-dom';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import HeatmapLayer from 'react-leaflet-heatmap-layer';
import { my_addressPoints } from './realworld.10000.js';

class Heatmap_comp extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            markers_heat: []
        };
    }

    componentDidMount() {
        console.log("MyClusterMap_2 DIDMOUNT", this.props)
        // this.setState({ markers_heat: this.props.coords })
    }

    render() {
        return (
            <div>
                <Map center={[40, -3.2]} zoom={5}>
                    <HeatmapLayer
                        // fitBoundsOnLoad
                        // fitBoundsOnUpdate
                        points={my_addressPoints}
                        longitudeExtractor={m => m[1]}
                        latitudeExtractor={m => m[0]}
                        intensityExtractor={m => parseFloat(m[2])} />
                    <TileLayer
                        url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                </Map>
            </div>
        );
    }

}

export default Heatmap_comp;