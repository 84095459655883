const capitalize = name => {
    var capitalized = name
    if (name != null) {
        capitalized = name.substr(0, 1).toUpperCase() + name.substr(1).toLowerCase();
    }
    return capitalized;
};

const lowercase = name => {
    return name.toLowerCase();
};

const uppercase = name => {
    return name.toUpperCase();
};

module.exports = {
    capitalize,
    lowercase,
    uppercase
};