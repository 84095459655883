import React from "react";

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

const referencias = (props) => (
    <Row>
        <Col xs={12}>
            <h3 className={`species_general_references_title`}>{props.t('gerenal_references')}</h3>
            <div className="species_general_references">
                <Accordion>
                    {props.referencias.map((refer, i) => {
                        // console.log(i, " - ", refer)
                        return <Card key={i}>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey={i}>
                                    <ul className="fa-ul mb-0 ml-2">
                                        <li><span className="fa-li"><i className="far fa-bookmark"></i></span>{refer.publication_cite}</li>
                                    </ul>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={i}>
                                <Card.Body>
                                    <ul className="fa-ul ml-3">
                                        <li>
                                            <strong><span className="fa-li"><i className="fas fa-angle-right"></i></span>Referencia: </strong>{refer.reference}
                                        </li>
                                    </ul>
                                    {refer.publication_hyperlink != null
                                        ? <div className="text-right">
                                            <a className="btn btn-outline-dark" href={refer.publication_hyperlink} target="_blank">{props.t('reference_link')}</a>
                                        </div>
                                        : null
                                    }
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    })}
                </Accordion>
            </div>
        </Col>
    </Row>

);

export default referencias;