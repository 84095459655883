import React from "react";

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


const disclaimer = (props) => (

    <Container fluid className={"disclaimer_banner " + (props.site == "home" ? "disclaimer-home" : "mt-5")}>
        <Row>
            <Col lg={12} md={12}>
                <div className="footer-disclaimer">
                    <span>{props.t('disclaimer')}</span>
                </div>
            </Col>
        </Row>
    </Container>

);

export default disclaimer;