import React from "react";

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Carousel from 'react-bootstrap/Carousel'

const carrousel_images = (props) => (

    <Row>
        <Col xs={12}>
            <h3 className={`species_general_references_title`}>{props.t('images')}</h3>
        </Col>
        <Col xs={12}>
            <Carousel>
                {props.images.map((img, i) => {
                    if (img.featured != 1) {
                        console.log("img.file_name", `http://admin.eei.sibic.org/assets/img/species/${img.file_name}`)
                        return <Carousel.Item key={i}>
                            <Image src={`http://admin.eei.sibic.org/assets/img/species/${img.file_name}`} fluid />
                             {img.author != "" 
                                ? 
                                    <Carousel.Caption>
                                        <small className="p-1 species_copyright_carousel" style={{color: "white", background: "rgba(0, 0, 0, 0.5)"}}>&copy; {img.author} </small>
                                    </Carousel.Caption> 
                                : null}
                            
                        </Carousel.Item>
                    }
                })}
            </Carousel>
        </Col>
    </Row >
);

export default carrousel_images;