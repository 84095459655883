import React from "react";

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const web_links = (props) => (

    <Row>
        <Col xs={12}>
            <h3 className={`species_general_references_title`}>{props.t('web_references')}</h3>
        </Col>
        <Col xs={12}>
            <ul className="fa-ul">
                {props.webpages.map((web, i) => {
                    return <li key={i}>
                        <span className="fa-li">
                            <i className="fas fa-spinner fa-pulse"></i>
                        </span><a href={web.webpage} target="_blank">{web.webpage}</a>
                    </li >
                })}
            </ul>
        </Col>
    </Row >
);

export default web_links;