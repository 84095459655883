import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect  } from "react-router-dom";
import { BrowserRouter } from 'react-router-dom'

import Home from './components/UI/home'
import Species from './components/Species/main_species.js'
import Cuencas from './components/Cuencas/main_cuencas.js'
import Common_names from "./components/common_names/common_names";
import Family from "./components/Famliy/family"
import Participantes from "./components/UI/participantes"
import Main_Nav from "./components/Nav_bar/main_navbar"
import Footer from "./components/UI/footer"

import Not_found_page from "./components/UI/error_404"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import INVASAQUA_LOGO from "./img/logos_footer/INVASAQUA_LOGO.png";
import NATURA2000_LOGO from "./img/logos_footer/LOGO NATURA2000.png";
import LIFE_LOGO from "./img/logos_footer/LOGO_LIFE.jpg";



class App extends Component {
    render() {
        return (
            <div>
                <BrowserRouter>
                    <div>
                        <Main_Nav />
                        
                        <Switch>
                            <Route exact path="/common_names" component={Common_names} />
                            <Route exact path="/species" component={Species} />
                            <Route exact path="/cuencas" component={Cuencas} />
                            <Route exact path="/realm" component={Family} />
                            <Route exact path="/participants" component={Participantes} />
                            <Route exact path="/" component={Home} />    
                            <Route component={Not_found_page} />
                        </Switch>
                    </div>
                </BrowserRouter>
                
            </div>
        );
    }
}

export default App;

