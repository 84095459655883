import React from "react";

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

const pathways = (props) => (
    <Row>
        <Col xs={12}>
            <h3 className={`species_general_references_title`}>{props.t('pathways')}</h3>
        </Col>

        {props.pathways.map((pathway, i) => {
            return <Col key={i} md={4}>
                {pathway.specific_img != null ?
                    <span className="pathway_hover">
                        <Image className="pathway_img" src={`http://admin.eei.sibic.org/assets/img/specific_pathways/${pathway.specific_img}`} rounded />
                        <h5 className={`habitat_path_general_title pathway`}>
                            {pathway.specific_pathway}
                        </h5>
                    </span>
                    :
                    <h5 className={`habitat_path_general_title`}>{pathway.specific_pathway}</h5>}
            </Col>
        })}
        <Col xs={12}>
            <hr />
        </Col>
    </Row >
);

export default pathways;