// cuencas.component.js

import React, { Component } from "react";

import axios from "axios";
import { withTranslation } from 'react-i18next';

// Componentes
import SearchInput, { createFilter } from 'react-search-input'
import Specie_info from "../Species/specie_info"
import Footer from "../UI/footer"

// Componentes Bootstrap
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

// CONSTANTES
import { API_URL } from '../../_aux/constants'

// CSS
import "../css/search_input.css"
import "../../App.css"

// Aux functions
const utils = require("../../_aux/aux_functions");
const { capitalize, lowercase, uppercase } = utils;


const KEYS_TO_FILTERS = ['cuenca']

class Cuencas extends Component {
    constructor(props) {
        super(props);
        this.searchUpdated_cuencas = this.searchUpdated_cuencas.bind(this)
        this.changeSpecie = this.changeSpecie.bind(this)

        this.state = {
            loading: true,
            api_byName: [],
            api_cuencas: [],
            searchTerm_cuenca: '',
            show_specie: [],
            language: this.props.i18n.language
        };
    }

    check_metatags(idioma){
        switch (idioma) {
            case "es":
                document.title = "Información de las Especies Exóticas Invasoras (EEI) en función de las Cuencas"
                document.querySelector('meta[name="description"]').content = "Información de las Especies Exóticas Invasoras (EEI) en función de las Cuencas: Cataluña, Duero, Ebro, Guadalquivir, Guadiana, Júcar, Miño-sil, Segura, Tajo y Cantábrico occidental."
                break;
            case "pt":
                document.title = "Informações sobre Espécies Exóticas Invasoras (EEI) de acordo com as Bacias."
                document.querySelector('meta[name="description"]').content = "Informações sobre Espécies Exóticas Invasoras (EEI) de acordo com as Bacias: Bacias do cantábrico ocidental, Bacias internas de catalunha, Douro, Ebro, Guadalquivir, Guadiana, Júcar, Minho, Segura e Tejo."
                break;
            default:
                document.title = "Information on Invasive Alien Species (IAS) according to the Basins."
                document.querySelector('meta[name="description"]').content = "Information on Invasive Exotic Species (EEI) according to the Basins: Cataluña, Duero, Ebro, Guadalquivir, Guadiana, Júcar, Miño-sil, Segura, Tajo and Western cantabrian."
                break;
        }
    }

    componentDidMount() {
        // Llamada con axios a la API de sibic
        //console.log("this.props - ", document.querySelector('meta[name="description"]').content);
        this.check_metatags(this.props.i18n.language);
        axios.get(API_URL + "/cuencas.php?lang=" + this.props.i18n.language).then(res => {
            const api_byName = res.data["species"];

            var api_cuencas = res.data["species"];
            // console.log("api_cuencas", api_cuencas)

            var array_cuencas = []
            var array_species = []
            var ant_hydr = ""
            api_cuencas.forEach((elem, i) => {
                if (ant_hydr == elem.hydrobasin || ant_hydr == "") {
                    array_species.push(elem)
                    if (i == api_cuencas.length - 1) {
                        var cuenca_data = { cuenca: ant_hydr, speceies: array_species }
                        array_cuencas.push(cuenca_data)

                        // Vacio el array para meter el nuevo valor
                        array_species = []
                        array_species.push(elem)
                    }
                } else {
                    var cuenca_data = { cuenca: ant_hydr, speceies: array_species }
                    array_cuencas.push(cuenca_data)

                    // Vacio el array para meter el nuevo valor
                    array_species = []
                    array_species.push(elem)
                }

                ant_hydr = elem.hydrobasin
            })

            this.setState({ api_byName, api_cuencas: array_cuencas, show_specie: res.data.species[0].species_id });

        }).then(() => {

            console.log("STATE", this.state.api_cuencas)
            this.setState({ loading: false });
        });
    }

    componentWillReceiveProps(next_props) {
        this.check_metatags(next_props.i18n.language);
        this.setState({ language: next_props.i18n.language })
        var query = API_URL + "/cuencas.php?lang=" + next_props.i18n.language;

        console.log("componentWillReceiveProps - ", this.props.i18n.language);
        console.log("query - ", query);
        axios.get(query).then(res => {
            const api_byName = res.data["species"];
            var api_cuencas = res.data["species"];
            console.log("api_cuencas", api_cuencas)

            var array_cuencas = []
            var array_species = []
            var ant_hydr = ""

            api_cuencas.forEach((elem, i) => {
                if (ant_hydr == elem.hydrobasin || ant_hydr == "") {
                    array_species.push(elem)
                    if (i == api_cuencas.length - 1) {
                        var cuenca_data = { cuenca: ant_hydr, speceies: array_species }
                        array_cuencas.push(cuenca_data)

                        // Vacio el array para meter el nuevo valor
                        array_species = []
                        array_species.push(elem)
                    }
                } else {
                    var cuenca_data = { cuenca: ant_hydr, speceies: array_species }
                    array_cuencas.push(cuenca_data)

                    // Vacio el array para meter el nuevo valor
                    array_species = []
                    array_species.push(elem)
                }

                ant_hydr = elem.hydrobasin
            })

            this.setState({ api_byName, api_cuencas: array_cuencas });
        }).then(() => {

            console.log("STATE props", this.state.api_cuencas)
            this.setState({ loading: false });
        });
    }

    changeSpecie(e) {
        e.preventDefault()
        console.log(e.target.id)

        var specie = this.state.api_byName.filter(elem => elem.species_id == e.target.id);
        console.log("changeSpecie handler - ", specie[0].species_id)

        this.setState({ show_specie: specie[0].species_id });
    }

    searchUpdated_cuencas(term) {
        this.setState({ searchTerm_cuenca: term })
    }

    render() {
        const { t } = this.props;
        // Lista para ser filtrada por la constante KEYS_TO_FILTERS
        const filteredCuencas = this.state.api_cuencas.filter(createFilter(this.state.searchTerm_cuenca, KEYS_TO_FILTERS))

        console.log("IDIOMA Species - ", this.state.language);

        // Listado de especies y familias en la navegación de la izquierda

        var lista_cuencas = filteredCuencas.map((specie, i) => {
            // console.log("speciespecie", specie)
            return (
                <Card style={{ borderBottom: "1px solid #fff", borderRight: "0", borderLeft: "0", borderTop: "0" }} key={i} >
                    <Accordion.Toggle style={{ padding: "0.7em" }} className="item_nav_specie item_card_header" as={Card.Header} eventKey={i}>
                        {capitalize(specie.cuenca)}<i className="fas fa-sort-down" style={{ float: "right" }}></i>
                    </Accordion.Toggle>
                    {specie.speceies.map((sp, j) => {
                        return (
                            <Accordion.Collapse key={i + j} eventKey={i}>
                                <Card.Body style={{ padding: "0.7em" }} className={`item_nav_specie ${this.state.show_specie == sp.species_id ? `active_red` : null}`} id={sp.species_id} onClick={this.changeSpecie}>{capitalize(sp.taxa)}</Card.Body>
                            </Accordion.Collapse>)
                    })}
                </Card>)

        })

        if (this.state.loading) return <div className="spinner">
            <Spinner animation="grow" variant="success" />
            <Spinner animation="grow" variant="info" />
        </div >

        return (
            <div className="container-fluid container-fluid-custom">
                <div className="row">
                    <div className="col-xs-5 col-sm-5 col-md-4 col-lg-3 border-right-1">

                        <nav className="navbar navBar_species">
                            <ul ref={this.lst_cuencas} className="navbar-nav navBar_species_ul list-group">
                                <li className="buscador"><SearchInput placeholder={t('search')} className="search-input" onChange={this.searchUpdated_cuencas} /></li>
                                <Accordion defaultActiveKey="0">
                                    {lista_cuencas}
                                </Accordion>
                            </ul>
                        </ nav >

                    </div>
                    <div className="col-xs-7 col-sm-7 col-md-8 col-lg-9">
                        <Specie_info data_specie={this.state.show_specie} />
                    </div>
                </div>
                <Footer />
            </div >
        );
    }
}

// export default Cuencas;
export default withTranslation()(Cuencas);
